import React from 'react';
import Image from 'reusecore/src/elements/Image';
import { Link } from 'gatsby';
import DocumentationSection from '../../../../../containers/SaasModern/Documentation';
import toodledo_1 from '../../../../../../../common/src/assets/image/toodledo_1.png';
import toodledo_configure from '../../../../../../../common/src/assets/image/toodledo_configure.png';
import toodledo_2 from '../../../../../../../common/src/assets/image/toodledo_2.png';
import toodledo_3 from '../../../../../../../common/src/assets/image/toodledo_3.png';

const Documentation = () => {
  const content = (
    <div>
      <p>
        PrintableCal can{' '}
        <Link to="/Documentation/Create-Calendar/Choose-a-Template">
          create printable calendars
        </Link>{' '}
        containing tasks that are defined in Toodledo. Here's how:
      </p>
      <ol className="numbered">
        <li>
          Click the <strong>Add Calendar</strong> button on the Calendar Data
          Sources step and select the <strong>Toodledo </strong>option. The{' '}
          <strong>Add Calendar Source - Toodledo</strong> window will appear.
          <br />
          <Image alt="" src={toodledo_1} style={{ width: 690 }} />
        </li>
        <li>
          Open your web browser and log into your Toodledo account at{' '}
          <a
            href="https://www.toodledo.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            https://www.toodledo.com/
          </a>
          .
        </li>
        <li>
          Click the profile button in the upper-right corner and select{' '}
          <strong>
            <em>More...</em>
          </strong>{' '}
          under <strong>Tools</strong>.
        </li>
        <li>
          Click <strong>Configure</strong> under the <strong>Calendars</strong>{' '}
          tool, or{' '}
          <a
            href="https://www.toodledo.com/tools/sync_ical.php"
            target="_blank"
            rel="noopener noreferrer"
          >
            click here
          </a>
          .<br />
          <Image alt="" src={toodledo_configure} style={{ width: 690 }} />
        </li>
        <li>
          Make sure the <strong>Enable Calendar Subscriptions</strong> box has a
          checkmark. If it doesn't, check the box and then click the{' '}
          <strong>Save Changes</strong> button.
          <br />
          <Image alt="" src={toodledo_2} style={{ width: 690 }} />
        </li>
        <li>
          Right-click the URL under <strong>Events</strong>.
        </li>
        <li>
          Copy the calendar URL by clicking the{' '}
          <strong>Copy link address</strong> command on the popup menu.
          Depending on your web browser, this command might be named{' '}
          <strong>Copy shortcut</strong> or <strong>Copy Link Location</strong>.
          <br />
          <Image alt="" src={toodledo_3} style={{ width: 691 }} />
        </li>
        <li>
          After copying the link, the address field at the top of the{' '}
          <strong>Add Calendar Source - Toodledo </strong>window should be
          automatically filled. If that doesn't happen, right-click the address
          field and select <strong>Paste</strong> from the popup menu, or press{' '}
          <strong>Ctrl+V</strong>.
        </li>
        <li>
          Enter the name for your Toodledo calendar in the field below the
          calendar address field.
        </li>
        <li>
          The OK button will now be enabled. Click the OK button to add your
          Toodledo tasks to PrintableCal's list of available calendar sources.
        </li>
      </ol>
    </div>
  );

  return (
    <DocumentationSection
      url="https://www.printablecal.com/Documentation/Create-Calendar/Calendar-Data-Sources/Toodledo"
      commentsId="commentsplus_post_144_489"
      title="Print Calendars with Tasks From Toodledo"
      description="PrintableCal can create printable calendars containing tasks that are defined in Toodledo."
      keywords="toodledo, import toodledo, print toodledo, print tasks, calendar template, printable calendar, printable tasks, import tasks, Excel calendar, Word calendar"
      content={content}
    />
  );
};

export default Documentation;
